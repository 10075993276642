import React, { useEffect, useState } from 'react';
import { useParams, Link} from 'react-router-dom';
import { Container, Grid, Box, Card, CardActionArea, CardMedia, CardContent, Typography, Pagination, Skeleton } from '@mui/material';
import SimpleAlert from '../../components/AlertDialog';

import api from '../../services/api';
import Default from '../../assets/img/default.svg'
import PublicCard from '../../components/PublicCard';

import Model from '../../components/Model';

const PublicDashboard = () => {
	const { researchcenterid } = useParams()
	// const {equipmentsData,RCMetaData} = useLoaderData()
	const [equipments, setEquipments] = useState([]);
	const [rcMetadata, setRCMetadata] = useState({});
	const [open, setOpen] = useState(false);
	const [currentEquipment, setCurrentEquipment] = useState(null);
	const [page, setPage] = useState(1);
	const [loadingRC, setLoadingRC] = useState(true);
	const [loadingEquipments, setLoadingEquipments] = useState(true);
	const [showAlert, setShowAlert] = useState(false);
	const itemsPerPage = 6;

	const handleOpen = (equipment) => {
		setCurrentEquipment(equipment);
		setOpen(true);
	};
	const handleCloseAlert = () =>{
		setShowAlert(false);
	}
	const handleClose = () => {
		setCurrentEquipment(null);
		setOpen(false);
	};

	const handlePageChange = (event, value) => {
		setPage(value);
	};

	useEffect(() => {
		// if(equipmentsData){
		// 	setEquipments(equipmentsData);
		// 	setLoadingEquipments(false);
		// }
		// if(RCMetaData){
		// 	setRCMetadata(RCMetaData);
		// 	setLoadingRC(false);
		// }
		const fetchEquipments = async (id) => {
			try{
				const response = await api.get(`api/public/${id}/equipments`);
				if(response.status===404){
					setShowAlert(true);
				}
				if(response){
					setEquipments(response.data);
					setLoadingEquipments(false);
				}	
			}catch(error){
				console.error('Error:', error);
			}
		
		};
		const fetchRCMetadata = async (id) => {
			try{
				const response = await api.get(`api/public/research-centers/${id}`);
				if(response.status===404){
					setShowAlert(true);
				}
				if(response){
					setRCMetadata(response.data);
					setLoadingRC(false);
				}
			}catch(error){
				console.error('Error:', error);
			}
		}
		fetchEquipments(researchcenterid);
		fetchRCMetadata(researchcenterid);
	},[researchcenterid]);

	// Calculate equipments to be displayed on the current page
	const displayedEquipments = equipments.slice((page - 1) * itemsPerPage, page * itemsPerPage);

	return(
		<Box className="flex flex-col min-h-screen min-w-screen font-base bg-[#f2f2f2]">
			<Box className="flex flex-col bg-red-800 p-10 relative md:h-[400px] h-screen md:pt-20 pt-40 mb-3">
				<Link to="/">
					<p className="text-white md:text-5xl text-2xl font-bold"> 
						{loadingRC 
							? <Skeleton height="5rem"/>
							: rcMetadata.name
						}
					</p>
				</Link>
				<Box className='pl-16 md:w-[35rem] w-full absolute bottom-10 md:right-10 right-5'>
					<Typography component='div' variant='h6' className='text-right text-white'>
						{loadingRC 
							? <Skeleton variant="rounded" height="7rem"/>
							: rcMetadata.description
						}
					</Typography>
				</Box>
			</Box>
			<Container className='flex flex-col align-middle mb-5'>
				{loadingEquipments 
					? (<Skeleton variant="rounded" sx={{
						display: 'flex',
						width: '100%',
						height: '180px',
						mt:'5px',
						'@media (max-width: 768px)': {
							maxWidth: '100%', 
						},
					}}/>
					) : ( <Grid container spacing={2}>
							{(displayedEquipments.map((equipment) => (
								<Grid item key={equipment.id} xs={12} sm={6} md={4}>
									<Card 
										sx={{
											display: 'flex',
											width: '100%',
											maxWidth: '360px',
											height: '80px',
											'@media (max-width: 768px)': {
												maxWidth: '100%', 
											},
										}}
										elevation={20}
									>
										<CardActionArea sx={{ display: 'flex', height: '100%' }} onClick={() => handleOpen(equipment)}>
											<CardMedia
												component="img"
												sx={{ width: 80 }}
												image={Default}
											/>
											<CardContent sx={{ flex: '1 0 auto' }}>
												<Typography component="div" variant="p">
													{equipment.type}
												</Typography>
												<Typography variant="p" color="text.secondary" component="div">
													{equipment.model}
												</Typography>   
											</CardContent>
										</CardActionArea>
									</Card>
								</Grid>
							)
						))
					}
				</Grid>)}
				{equipments.length > itemsPerPage && (
					<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
						<Pagination
							count={Math.ceil(equipments.length / itemsPerPage)}
							page={page}
							onChange={handlePageChange}
						/>
					</Box>
				)}
			</Container>
			{open && currentEquipment &&
				<PublicCard
				open={open}
				handleClose={handleClose}
				equipment={currentEquipment}
				/>
			}

			{showAlert &&
			<SimpleAlert severity={"info"} message={"No equipment in current research center."} title={"Information"} handleCloseAlert={handleCloseAlert}/>}
		</Box>
	)
};

export default PublicDashboard;
