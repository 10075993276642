import React, {useState} from "react";
import {Modal} from '@mui/material';
import api from '../../services/api';
import SimpleAlert from "../../components/AlertDialog";

const AddDevice = ({handleOpenState, handleClose, openState, equipmentType, rcId, setAlertObject}) => {
    const [newType, setNewType] = useState("");
    const [newName, setNewName] = useState("");
    const [newStatus, setNewStatus] = useState("");
    const [newModel, setNewModel] = useState("");
    const [newSerialNo, setNewSerialNo] = useState("");
    const [newInventoryNo, setNewInventoryNo] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [newModel3D, setNewModel3D] = useState(null);
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const onExit = () => {
        setNewStatus("");
        setNewModel("");
        setNewSerialNo("");
        setNewInventoryNo("");
        setNewName("");
        setNewDescription("");
        newType!=null 
            ? handleOpenState("New")
            : handleOpenState(equipmentType)
    }

    const onExitAfterSubmission = () => {
        setNewStatus("");
        setNewModel("");
        setNewSerialNo("");
        setNewInventoryNo("");
        setNewName("");
        setNewDescription("");
        newType!=null 
            ? handleClose("New")
            : handleClose(equipmentType)
        
        setAlertObject({
            severity:"success",
            message: "Equipment added successfully.",
            title:"Succes",
            showAlert: true,
        });
    }

    const handleCreationSubmit = async (e) => {
        e.preventDefault();
        try {
            if(!newStatus){
                setError('Status required');
                setShowAlert(true);
                throw error;
            }
            if (newSerialNo === newInventoryNo) {
                setError('Serial Number and Inventory Number must be unique');
                setShowAlert(true);
                throw error;
            }
            const formData = new FormData();
            formData.append('type', equipmentType === "" ? newType : equipmentType);
            formData.append('model', newModel);
            formData.append('serialNo', newSerialNo);
            formData.append('inventoryNo', newInventoryNo);
            formData.append('status', newStatus);
            formData.append('researchCenterId', rcId);
            formData.append('name', newName);
            formData.append('description', newDescription);
            if (newModel3D) {
                formData.append('model3D', newModel3D); // Append file to formData
            }
            await api.post('/api/equipments/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            onExitAfterSubmission()
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
    <>
        <Modal
            open={openState}
            onClose={onExit}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            className="font-base"
            >
            <form onSubmit={handleCreationSubmit} className="
                    flex flex-col border-2 border-black
                    bg-white py-[17%] px-6 sm:p-6
                    sm:w-[80%] md:w-[70%] lg:w-[60%]
                    2xl:w-[50%] w-[100%]
                    h-full sm:h-auto bg-[#f2f2f2]
                    ">
                <div className="my-6">
                    <h2 id="parent-modal-title" className="text-lg font-bold text-red-800">{"Add New > "+ equipmentType}</h2>
                </div>
                <div>
                    <div className="flex flex-row gap-3 p-2">
                        <label htmlFor="name" className="basis-1/4">Name</label>
                        <input className="
                                border border-black rounded
                                w-[65%] py-1 px-2
                                text-gray-700 leading-tight
                                focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                            "
                            id="name"
                            value={newName}
                            required={true}
                            onChange={(e) => setNewName(e.target.value)}
                            placeholder="Enter Name"
                        />
                    </div>
                    <div className="flex flex-row gap-2 p-2">
                        <label htmlFor="status" className="basis-1/4">Status</label>
                        <button
                            type="button"
                            onClick={() => setNewStatus("available")}
                            className={`p-1 rounded-2xl w-[20%] text-sm overflow-hidden whitespace-nowrap text-ellipsis ${newStatus === "available" ? "bg-green-800 text-white": "bg-white border-2 border-green-800 text-green-800"}`}>Available
                        </button>
                        <button
                            type="button"
                            onClick={() => setNewStatus("for repair")}
                            className={`p-1 rounded-2xl w-[20%] text-sm overflow-hidden whitespace-nowrap text-ellipsis ${newStatus === "for repair" ? "bg-red-800 text-white": "bg-zinc-200 border-2 border-red-800 text-red-800"}`}>For Repair
                        </button>
                            <button
                                type="button"
                                onClick={() => setNewStatus("work-in-progress")}
                                className={`p-1 rounded-2xl w-[25%] text-sm overflow-hidden whitespace-nowrap text-ellipsis ${newStatus === "work-in-progress" ? "bg-orange-600 text-white": "bg-zinc-200 border-2 border-orange-600 text-orange-600"}`}>Work In Progress
                            </button>
                    </div>
                    { equipmentType===""
                        ?   <div className="flex flex-row gap-3 p-2">
                                <label htmlFor="newType" className="basis-1/4 py-2">Type</label>
                                <input className="
                                        border border-black rounded
                                        w-[65%] py-1 px-2
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                                    id="newType"
                                    value={newType}
                                    required={true}
                                    onChange={(e) => setNewType(e.target.value)}
                                    placeholder="3D Printer"
                                />
                            </div>
                        :   <></>
                    }
                    <div className="flex flex-row gap-3 p-2">
                        <label htmlFor="model" className="basis-1/4 py-2">Model</label>
                        <input className="
                                        border border-black rounded
                                        w-[65%] py-1 px-2
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                            id="model"
                            value={newModel}
                            required
                            onChange={(e) => setNewModel(e.target.value)}
                            placeholder="Ultimaker"
                        />
                    </div>
                    <div className="flex flex-row gap-3 p-2">
                        <label htmlFor="serialNo" className="basis-1/4 py-2">Serial Number</label>
                        <input className="
                                border border-black rounded
                                w-[65%] py-1 px-2
                                text-gray-700 leading-tight
                                focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                            "
                            id="serialNo"
                            value={newSerialNo}
                            required={true}
                            onChange={(e) => setNewSerialNo(e.target.value)}
                            placeholder="###"
                        />
                    </div>
                    <div className="flex flex-row gap-3 p-2">
                        <label htmlFor="inventoryNo" className="basis-1/4 py-2">Inventory Number</label>
                        <input className="
                                border border-black rounded
                                w-[65%] py-1 px-2
                                text-gray-700 leading-tight
                                focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                            "
                            id="inventoryNo"
                            required={true}
                            value={newInventoryNo}
                            onChange={(e) => setNewInventoryNo(e.target.value)}
                            placeholder="###"
                        />
                    </div>
                    <div className="flex flex-row gap-3 p-2">
                        <label htmlFor="description" className="basis-1/4 py-2">Description</label>
                        <textarea className="
                                flex border border-black rounded
                                w-[65%] py-2 px-3
                                text-gray-700 leading-tight
                                focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                            "
                            id="description"
                            value={newDescription}
                            onChange={(e) => setNewDescription(e.target.value)}
                            resize="none"
                            wrap="hard"
                            placeholder="Add a short description"
                        />
                    </div>
                    <div className="flex flex-row gap-3 p-2">
                        <label htmlFor="model3D" className="basis-1/4 py-2">3D Model</label>
                        <input className="
                                border border-black rounded
                                w-[65%] py-1 px-2
                                text-gray-700 leading-tight
                                focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm"
                            id="model3D"
                            type="file"
                            onChange={(e) => setNewModel3D(e.target.files[0])} // Handle file input
                        />
                    </div>
                </div>
            
                <div className="flex flex-row-reverse gap-4 mt-2">
                    <button
                        type="button"
                        onClick={onExit}
                        className="
                            p-1 bg-white border-2 border-red-800
                            rounded-2xl text-red-800 w-1/5 text-md
                            overflow-hidden whitespace-nowrap text-ellipsis
                            ">
                                Cancel
                    </button>
                    <button
                        type="submit"
                        className="
                            p-1 bg-red-800 rounded-2xl text-white
                            w-1/5 text-md overflow-hidden whitespace-nowrap
                            text-ellipsis"
                        >
                            Save
                    </button>
                </div>
                {showAlert &&
                    <SimpleAlert severity={"error"} message={error} title={"Error"} handleCloseAlert={handleCloseAlert}/>
                }
            </form>
        </Modal>
    </>
    );
}

export default AddDevice;