import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ListResearchCenter from "./List_ResearchCenter";
import {Skeleton} from '@mui/material';
// import { useLoaderData } from "react-router-dom";
import api from '../../services/api';
import SimpleAlert from '../../components/AlertDialog';

const Home = () => {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const handleCloseAlert = () =>{
		setShowAlert(false);
	}

  useEffect(() => {
		const fetchBranches = async (id) => {
      try{
        const response = await api.get('api/public/research-centers/list');
        if(response.status===404){
          setShowAlert(true);
        }
        setBranches(response.data);	
        setLoading(false);
      }catch(error){
        console.error('Error:', error);
      }
		};
		fetchBranches();
	},[]);

  // const branches = useLoaderData()
  return (
    <>
      <div className="flex flex-col gap-3 font-base h-auto">
        <div className=" bg-[#8B0000] p-4">
          <p className="text-white text-2xl m-4">
            STEERHub Research Equipment Monitoring
          </p>
        </div>
        <div className="w-[90%] mx-auto p-5">
          <p className="text-right text-[#8B0000] font-bold text-lg mt-[6%]">
            STEERHub Staff? <Link to="/login" className="underline">Login</Link>
          </p>
          {/* 33ms-UseEffect, 3ms-Loader */}
          <div className="mt-2">
            { loading
              ? <div className="flex flex-col flex-wrap gap-4 mt-7 lg:flex-row h-full">
                  <Skeleton variant="rounded" height="150px" className="w-full lg:w-[32%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
                  <Skeleton variant="rounded" height="150px" className="w-full lg:w-[32%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
                  <Skeleton variant="rounded" height="150px" className="w-full lg:w-[32%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
                  <Skeleton variant="rounded" height="150px" className="w-full lg:w-[32%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
                  <Skeleton variant="rounded" height="150px" className="w-full lg:w-[32%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
                  <Skeleton variant="rounded" height="150px" className="w-full lg:w-[32%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
                </div>
              : <ListResearchCenter branchList={branches}/>
            }
          </div>
        </div>
      </div>
      {showAlert &&
			<SimpleAlert severity={"info"} message={"No equipment in current research center."} title={"Information"} handleCloseAlert={handleCloseAlert}/>}
    </>
  );
};

export default Home;