import React from 'react';
import {Snackbar, Alert, AlertTitle} from '@mui/material';

export default function SimpleAlert({severity, message, title, handleCloseAlert}) {
    return (
        <Snackbar open={true} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert severity={severity} variant="filled" onClose={handleCloseAlert}>
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );
}