// import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider,} from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
// import api from "./services/api";

// Pages
import LoginForm from "./pages/login/LoginForm";
import Home from "./pages/home/Home";
import AdminHome from "./pages/admin_home/AdminHome";
import StaffHome from "./pages/staff_home/StaffHome"
import ErrorPage from "./pages/error/ErrorPage";
import PublicDashboard from "./pages/public_dashboard/PublicDashboard";

import ProtectedRoute from "./components/ProtectedRoute";

// Routes
// /researchcenterlist (placeholder)
// /center/:researchcenterid
// /device/:deviceid

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route
//       path="/"
//       element={<Root />}
//       errorElement={<ErrorPage />}
//     >
//       <Route errorElement={<ErrorPage />}>
//         <Route 
//           index 
//           element={<Home />} 
//           loader={ async () => {
//             const response = await api.get('api/research-centers/list');
//             return response.data
//           }}
//         />

//         <Route
//           path="/login"
//           element={ <LoginForm /> }
//         />

//         <Route
//           path="/center/:researchcenterid"
//           element={ <StaffHome /> }
//           // loader= { ({ params }) => {
//           //   // return fetch(`/api/teams/${params.teamId}.json`);
//           //   // params += "1"

//           //   // backend logic dito iyon
//           //   return params.researchcenterid
//           // }}
//         />

//         <Route
//           path="/public/:researchcenterid"
//           element={ <PublicDashboard /> }
//           // loader= { async ({ params }) => {
//           //   const responseEquipments =  await api.get(`api/equipments/${params.researchcenterid}/public`)
//           //   const responseMetaData =  await api.get(`api/research-centers/${params.researchcenterid}`)
//           //   return{equipmentsData:responseEquipments.data, RCMetaData:responseMetaData.data}
//           // }}
//         />

//         <Route
//           path="/admin"
//           element={ <AdminHome /> }
//         />
//       </Route>
//     </Route>
//   )
// );

export default function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const user = jwtDecode(token);
      dispatch({ type: 'SET_USER', payload: user });
      // Optionally, navigate to a default page
    }
  }, [dispatch]);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/public/:researchcenterid" element={<PublicDashboard />} />
      <Route path="/center/:researchcenterid" element={<ProtectedRoute><StaffHome /></ProtectedRoute>} />
      <Route path="/admin" element={<ProtectedRoute><AdminHome /></ProtectedRoute>} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
    // <RouterProvider router={router}/>
  );
}