import React from "react";
import {Modal} from '@mui/material';

const DeleteDialog = ({openState, handleDeleteDialogState,deleteName, setDeleteName, handleDeleteSubmit}) =>{
    return (
        <Modal
        open={openState}
        onClose={handleDeleteDialogState}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="
            px-5
            place-content-center
            bg-opacity-30
            font-base
        ">
            <form onSubmit={handleDeleteSubmit} 
                className="
                    md:m-auto md:w-1/3
                    bg-[#f2f2f2]    
                    border-2 border-black 
                "
            >
                <div className="py-4 flex flex-col items-center gap-5">
                    <p id="child-modal-description" className="">
                        Are you sure?
                    </p>
                    <div className="flex flex-row">
                        <label htmlFor="deleteName" className="w-1/5">Name</label>
                        <input className="
                                w-4/5 pl-1.5
                                border border-black rounded 
                                text-gray-700 leading-tight
                                focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                            "
                            id="deleteName"
                            value={deleteName}
                            required
                            onChange={(e) => setDeleteName(e.target.value)}
                            placeholder="Enter Name"
                        />
                    </div>

                    <div className="flex gap-7">
                        <button type="submit" className="
                            w-20 
                            bg-red-800 rounded-2xl 
                            text-white text-md overflow-hidden whitespace-nowrap text-ellipsis"
                        >
                            Yes
                        </button>
                        <button type="button" onClick={handleDeleteDialogState} 
                            className="
                                w-20 
                                bg-white border-2 border-red-800 rounded-2xl 
                                text-red-800 text-md overflow-hidden whitespace-nowrap text-ellipsis
                            "
                        >   
                            No
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default DeleteDialog;