import {Link} from "react-router-dom";
import React,{ useRef } from 'react';
import {Card, CardActionArea, CardContent, Typography} from '@mui/material';

function List_ResearchCenter({branchList}) {
	// const touchRippleRef = useRef(null);
	const rippleRefs = useRef({});
	const handleMouseOver = (event,branchId) => {
		const touchRippleRef = rippleRefs.current[branchId];
		if (touchRippleRef.current) {
			const clientX = event.clientX
			const clientY = event.clientY
			const rippleStart = touchRippleRef.current.start({ clientX, clientY }, { pulsate: false });
			setTimeout(() => {
				touchRippleRef.current.stop(rippleStart);
			}, 250)
		}
	};
	const list = branchList.map((branch) => { 
		if (!rippleRefs.current[branch.id]) {
			rippleRefs.current[branch.id] = React.createRef();
		}  
		return(
		<React.Fragment>
			<Card
			key={branch.id}
			sx={{
				width: "100%",
				height: 150,
				boxShadow: 10,
				background: "rgb(153 27 27)",
			}}
			elevation={20}>
				<Link
					key={branch.id}
					to={`/public/${branch.id}`}
					>
					<CardActionArea
						sx={{ height: '100%' }} 
						touchRippleRef={rippleRefs.current[branch.id]}
						onMouseOver={(event) => handleMouseOver(event, branch.id)}>
						<CardContent
							sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
							color: 'white',
							}}
						>
							<Typography variant='h6' component='div'sx={{pointerEvents: 'none'}}>
									<li key={branch.id} className="self-center text-center">
										{branch.name}
									</li>
							</Typography>
						</CardContent>
					</CardActionArea>
				</Link>
			</Card>
		</React.Fragment>
		)
})

	return (
		<>
			<ul className="grid grid-cols-1 md:grid-cols-3 gap-5 size-full">
				{ list }
			</ul>
		</>
	);
}

export default List_ResearchCenter;

