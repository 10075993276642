import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SimpleAlert from '../../components/AlertDialog';
import { login } from '../../_actions/authActions';
import { useDispatch } from 'react-redux';
import { jwtDecode } from "jwt-decode";
import { Navigate } from 'react-router-dom';

const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isAdmin, setIsAdmin] = useState(false)

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('');

    const [type, setType] = useState('password'); 

    const [showAlert, setShowAlert] = useState(false);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleVisibility = () => {
        if (type==='password'){
            setType('text');
        } else {
            setType('password');
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const actionResult = await dispatch(login({ code: username, password: password }));
            if (actionResult.success) {
                localStorage.setItem('token', actionResult.token);
                const user = jwtDecode(actionResult.token);
                dispatch({ type: 'SET_USER', payload: user });
                if (user.role === 'admin' && !isAdmin) {
                    setError('Admin login is not allowed here.');
                    setShowAlert(true);
                    localStorage.removeItem('token');
                    return; // Prevent further actions
                }
                if (user.role === 'research-center') {
                    navigate('/center/'+user.id);
                } else {
                    navigate('/admin');
                }
                } else {
                    console.error("Login failed:", actionResult.message);
            }
        } catch (error) {
            setUsername("")
            setPassword("")
            setError('Login failed. Please check your credentials and try again.');
            setShowAlert(true)
            localStorage.removeItem('token');
            console.error('Error:', error);
        }
    };

    //TODO ilagay sa if statement na i-check ang role mula sa token before deciding kung san navigation
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            // Redirect based on user role
            if (user.role === 'research-center') {
                navigate(`/center/${user.id}`);
            } else {
                navigate('/admin');
            }
        }
    }, [navigate]);

    const handleAdminState = () =>{
        setIsAdmin(!isAdmin);    
    };

    return (
        <div className="
                min-h-screen min-w-screen 
                flex flex-col 
                md:flex-row md:justify-between 
                font-base
            "
        >
            <div className="
                    h-[25vh] md:h-screen md:w-[60%] 
                    place-content-center 
                    bg-gradient-to-t from-red-400 to-red-800
                "
            >
                <p className='text-2xl text-center text-white font-bold'>
                    Batangas State University
                </p>
                <p className='text-lg text-center text-white font-normal'>
                    The National Engineering University
                </p>
            </div>

            <div className="
                    h-[55vh] md:h-screen md:w-[40%] 
                    flex-col place-content-center
                "
            >
                <div className="text-center">
                    <h1 className="text-lg font-bold text-red-800">
                        STEERHub Research Equipment
                    </h1>
                    <h1 className="text-lg font-bold text-red-800">
                        {isAdmin ? "Admin System Portal" : "Monitoring System Portal"}
                    </h1>
                </div>

                <div className="
                        pt-7 px-10 
                        flex flex-col gap-8">
                    <form className='flex flex-col gap-5' onSubmit={handleSubmit}>
                        <div className= "min-w-full flex flex-col place-items-stretch">
                            <label htmlFor="username">
                                {isAdmin ?"Admin":"Research Center Code"}
                            </label> 
                            <input 
                                className='
                                    border border-black rounded 
                                    min-w-full py-2 px-3 
                                    text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline focus:border-red-600 
                                    focus:shadow-red-800 focus:shadow-sm
                                '
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required={true}
                                placeholder='Enter Credentials'
                                />
                        </div>
                        <div className="min-w-full flex flex-col place-items-stretch">
                            <label htmlFor="password">Password</label>
                            <div className="min-w-full">
                                <>
                                    <input 
                                        className='
                                            border border-black rounded 
                                            w-full py-2 pl-3 pr-8
                                            text-gray-700 leading-tight
                                            focus:outline-none focus:shadow-outline focus:border-red-600 
                                            focus:shadow-red-800 focus:shadow-sm
                                        '
                                        type={type}
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required={true}
                                        placeholder="Password"
                                    />
                                    <span className="absolute translate-x-[-1.85rem] translate-y-[0.4rem] cursor-pointer z-10" onClick={handleVisibility}>
                                        {type==="password"
                                        ? <VisibilityOffOutlinedIcon/>
                                        : <VisibilityOutlinedIcon/>}
                                    </span>
                                </>
                            </div>
                        </div>
                        
                        <div className="flex justify-center">
                            <button type="submit" 
                                className="
                                    w-20 py-1.5 bg-red-900 rounded-md 
                                    text-white text-md
                                    overflow-hidden whitespace-nowrap text-ellipsis
                                    box-border hover:border-red-800 hover:bg-white hover:text-black 
                                    hover:shadow-red-800 hover:shadow-sm
                                    hover:transition duration-200 ease-linear
                                "
                            >
                                Login
                            </button>
                        </div>
                    </form>

                    <div className="font-base text-red-800 flex flex-col justify-between text-sm gap-3 text-nowrap">
                        <span>
                            {isAdmin ? "User? Click " : "Admin? Click "}
                            <button onClick={handleAdminState} className="w-auto underline">
                                here
                            </button>
                        </span>
                        <span className="w-auto">
                            {"Back to "} 
                            <Link to="/" className="underline">
                                Home 
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
            {showAlert &&
                <SimpleAlert severity={"error"} message={error} title={"Error"} handleCloseAlert={handleCloseAlert}/>
            }
        </div>
    );
};

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  const isAuthenticated = token ? true : false;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default LoginForm;