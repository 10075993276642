import React, {useState, useEffect, useCallback} from "react";
import {Modal, Skeleton} from '@mui/material';
import api from '../../services/api';
import DeleteDialog from "./DeleteDialog";
import DateDisplay from "../../components/DateDisplay";
import SimpleAlert from "../../components/AlertDialog";

const DeviceInfo = ({open,handleClose,equipmentId,handleChangesAfterClose,researchCenterId, setAlertObject}) => {
    const [loading, setLoading] = useState(true);
    const [editable, setEditable] = useState(false);
    const [equipment, setEquipment] = useState({});

    const [name, setName] = useState("");
    const [deleteName, setDeleteName] = useState("");
    const [status, setStatus] = useState("");
    const [model, setModel] = useState("");
    const [serialNo, setSerialNo] = useState("");
    const [inventoryNo, setInventoryNo] = useState("");
    const [type, setType] = useState("");
    const [description, setDescription] = useState("");
    const [model3D, setModel3D] = useState(null);

    const [equipmentLog, setEquipmentLog] = useState({});

    const [error, setError] = useState('');

    const [showAlert, setShowAlert] = useState(false);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleEditable = () =>{
        setEditable(!editable)
        setStatus(equipment.status)
        setModel(equipment.model)
        setSerialNo(equipment.serialNo)
        setInventoryNo(equipment.inventoryNo)
        setDescription(equipment.description)
        setType(equipment.type)
    }

    const [deleteOpen, setDeleteOpen] = useState(false);
    const handleDeleteDialogState = () =>{
        setDeleteOpen(!deleteOpen)
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            if (serialNo === inventoryNo) {
                setError('Serial Number and Inventory Number must be unique');
                setShowAlert(true);
                throw error;
            }
            const formData = new FormData();
            formData.append('type',  type);
            formData.append('model', model);
            formData.append('serialNo', serialNo);
            formData.append('inventoryNo', inventoryNo);
            formData.append('status', status);
            formData.append('name', name);
            formData.append('description', description);
            if (model3D) {
                formData.append('model3D', model3D); // Append file to formData
            }
            await api.put(`/api/equipments/${equipmentId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            handleChangesAfterClose();
            setAlertObject({
                severity:"info",
                message: "Equipment edited successfully.",
                title:"Information",
                showAlert: true,
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!deleteName) {
                setError('All fields are required.');
                throw error;
            }
            await api.delete(`/api/equipments/${equipmentId}`,{
                data: {
                    name: deleteName,
                    researchCenterId: equipment.researchCenterId
                }
            });
            setDeleteName("")
            handleChangesAfterClose();
            setAlertObject({
                severity:"info",
                message: "Equipment deleted successfully.",
                title:"Information",
                showAlert: true,
            });
        } catch (error) {
            setError("Delete Failed.")
            console.error('Error:', error);
        }
    };

    const fetchEquipment = useCallback(async (e) =>{
        try {
            const response = await api.get(`api/equipments/${equipmentId}`);
            if(response){
                const responseData = response.data
                const log = responseData.log
                const equipment = responseData.equipment
                setEquipment(equipment)
                setEquipmentLog(log)
                setLoading(false)
            }
        } catch (error) {
            setError('Fail');
            console.error('Error:', error);
        }
    }, [equipmentId]);

    useEffect(() => {
        fetchEquipment()
	},[fetchEquipment]);

    return (
    <>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            hideBackdrop = {false}
            className='
                w-full h-screen bg-opacity-30
                flex flex-col justify-center items-center
                font-base
            '
        >{editable 
            ? (<div className='
                w-full h-full p-7 
                md:h-auto md:w-[50%]
                flex flex-col justify-center
                border-2 border-black 
                bg-[#f2f2f2]'
            >
                <div className='flex flex-col text-lg'>
                    <h2 id="parent-modal-title" 
                        className="text-2xl font-bold text-red-800"
                    >
                        {loading ? <Skeleton/>  : equipment.type + " > " + equipment.model}
                    </h2> 
                </div>

                <form onSubmit={handleEditSubmit}>
                    <div className="flex flex-col gap-3 py-4">
                        <div className="flex flex-row">
                            <label htmlFor="name" 
                                className="
                                    w-1/3
                                    self-center
                                "
                            >
                                Name
                            </label>
                            <input className="
                                    border border-black rounded 
                                    w-2/3 pl-1.5
                                    text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                "
                                id="name"
                                value={name}
                                required={true}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Name"
                            />
                        </div>

                        <div className="flex flex-row">
                            <label htmlFor="status" 
                                className="
                                    w-1/3
                                    self-center
                                "
                            >
                                Status
                            </label>

                            <div className="flex flex-col w-2/3 gap-2">
                                <button 
                                    type="button"
                                    onClick={() => setStatus("available")} 
                                    className={`
                                        py-1.5 w-full 
                                        rounded-2xl 
                                        text-sm overflow-hidden whitespace-nowrap text-ellipsis 
                                        ${status === "available" ? "bg-green-800 text-white": "bg-white border-2 border-green-800 text-green-800"}
                                    `}
                                >
                                    Available
                                </button> 
                                <button 
                                    type="button"
                                    onClick={() => setStatus("for repair")} 
                                    className={`
                                        py-1.5 w-full 
                                        rounded-2xl text-sm overflow-hidden whitespace-nowrap text-ellipsis 
                                        ${status === "for repair" ? "bg-red-800 text-white": "bg-zinc-200 border-2 border-red-800 text-red-800"}
                                    `}
                                >
                                    For Repair
                                </button>
                                <button 
                                    type="button"
                                    onClick={() => setStatus("work-in-progress")} 
                                    className={`
                                        py-1.5 w-full 
                                        rounded-2xl text-sm overflow-hidden whitespace-nowrap text-ellipsis 
                                        ${status === "work-in-progress" ? "bg-orange-600 text-white": "bg-zinc-200 border-2 border-orange-600 text-orange-600"}
                                    `}
                                >
                                    Work In Progress
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-row">
                            <label htmlFor="type" 
                                className="
                                    w-1/3
                                    self-center
                                "
                            >
                                Type
                            </label>
                            <input className="
                                    border border-black rounded 
                                    w-2/3 pl-1.5
                                    text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                "
                                id="type"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                placeholder="3D Printer"
                            />
                        </div>

                        <div className="flex flex-row">
                            <label htmlFor="model" 
                                className="
                                    w-1/3
                                    self-center
                                "
                            >
                                Model
                            </label>
                            <input className="
                                    border border-black rounded 
                                    w-2/3 pl-1.5
                                    text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                "
                                id="model"
                                value={model}
                                onChange={(e) => setModel(e.target.value)}
                                placeholder="Ultimaker"
                            />
                        </div>

                        <div className="flex flex-row">
                            <label htmlFor="serialNo" 
                                className="
                                    w-1/3
                                    self-center
                                "
                            >
                                Serial No.
                            </label>
                            <input className="
                                    border border-black rounded 
                                    w-2/3 pl-1.5
                                    text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                "
                                id="serialNo"
                                value={serialNo}
                                onChange={(e) => setSerialNo(e.target.value)}
                                placeholder="###"
                            />
                        </div>

                        <div className="flex flex-row">
                            <label htmlFor="inventoryNo" 
                                className="
                                    w-1/3
                                    self-center
                                "
                            >
                                Inventory No.
                            </label>
                            <input className="
                                    border border-black rounded 
                                    w-2/3 pl-1.5
                                    text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                "
                                id="inventoryNo"
                                value={inventoryNo}
                                onChange={(e) => setInventoryNo(e.target.value)}
                                placeholder="###"
                            />
                        </div>

                        <div className="flex flex-row">
                            <label htmlFor="description" 
                                className="
                                    w-1/3
                                    self-center
                                "
                            >
                                Description
                            </label>
                            <textarea 
                                className="
                                    flex border border-black rounded
                                    w-2/3 h-20 pl-1.5
                                    text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                "
                                id="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                resize="none"
                                wrap="hard"
                                placeholder="Add a short description"
                                contenteditable
                            />
                        </div>
                        <div className="flex flex-row">
                            <label htmlFor="model3D" 
                                className="
                                    w-1/3
                                    self-center
                                "
                            >
                                3D Model
                            </label>
                            <input className="
                                    border border-black rounded 
                                    w-2/3
                                    text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm"
                                id="model3D"
                                type="file"
                                onChange={(e) => setModel3D(e.target.files[0])} // Handle file input
                            />
                        </div>

                        {/* Might Delete */}
                        {/* <div className="flex flex-row gap-3 p-2">
                            <label htmlFor="researchCenterId" className="basis-1/4 py-2">Research Center ID</label>
                            <input className="
                                    border border-black rounded 
                                    w-[65%] py-2 px-3
                                    text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                "
                                id="researchCenterId"
                                disabled
                                value={researchCenterId}
                            />
                        </div> */}
                    </div>

                    <div className="
                        flex 
                        flex-col md:flex-row 
                        items-center md:justify-center
                        gap-4
                        "
                    >
                        <button type="submit" 
                            className="
                                w-4/5 h-full md:w-1/3 p-2
                                bg-red-800 rounded-2xl 
                                text-white text-md overflow-hidden whitespace-nowrap text-ellipsis
                            "
                        >
                            Save
                        </button>
                        <button type="button" onClick={() => setEditable(!editable)} 
                            className="
                                w-4/5 h-full md:w-1/3 p-2
                                bg-white border-2 
                                border-red-800 rounded-2xl 
                                text-red-800 text-md overflow-hidden whitespace-nowrap text-ellipsis
                            "
                        >
                            Cancel
                        </button>
                    </div>

                    {showAlert &&
                        <SimpleAlert severity={"error"} message={error} title={"Error"} handleCloseAlert={handleCloseAlert}/>
                    }
                </form>
            </div>) : 
            (<div className='
                    w-full h-full p-4 py-8
                    md:h-auto md:w-1/2
                    flex flex-col justify-center
                    border-2 border-black 
                    bg-[#f2f2f2]'
                >
                    <div className=''>
                        <h2 id="parent-modal-title" 
                            className="
                                text-2xl font-bold text-red-800
                            "
                        >
                            {loading ? <Skeleton/>  : equipment.type + " > " + equipment.model}
                        </h2> 
                    </div>
                    <div className='flex flex-col'>
                        <div className='
                            py-10 px-5
                            flex flex-col gap-3
                            text-lg
                        '>
                            <div className='flex'>
                                <p className="w-2/5">Status:</p>
                                <p className='w-3/5'>{loading ? <Skeleton /> : equipment.status}</p>
                            </div>
                            {loading 
                                ? <Skeleton/>
                                : (equipmentLog && <DateDisplay equipmentLog={equipmentLog} equipmentStatus={equipment.status} />)
                            }
                            
                            <div className="flex items-center">
                                <p className="w-2/5">Serial Number:</p>
                                <p className='w-3/5'>{loading ? <Skeleton /> : equipment.serialNo }</p>
                            </div>
                            
                            <div className="flex items-center">
                                <p className="w-2/5">Property Number: </p>
                                <p className='w-3/5'>{loading ? <Skeleton /> : equipment.inventoryNo }</p>
                            </div>

                            <div className="flex items-center">
                                <p className="w-2/5">Description: </p>
                                <p className='w-3/5 h-20 overflow-y-scroll text-justify text-sm'>{loading ? <Skeleton /> : equipment.description}</p>
                            </div>
                        </div>
                        
                        <div className='
                            flex 
                            flex-col md:flex-row 
                            items-center md:justify-center
                            gap-4
                        '>
                            <button onClick={handleEditable} 
                                className="
                                    w-4/5 h-full md:w-1/3 p-2 
                                    bg-red-800 rounded-2xl 
                                    text-white
                            ">
                                Edit
                            </button>

                            <button onClick={handleDeleteDialogState} 
                                className="
                                    w-4/5 h-full md:w-1/3 p-2 
                                    bg-red-800 rounded-2xl 
                                    text-white
                            ">
                                Remove Device
                            </button>
                            
                            <button onClick={handleClose} 
                                className="
                                    w-4/5 h-full md:w-1/3 p-2 
                                    bg-white border-2 
                                    border-red-800 rounded-2xl 
                                    text-red-800
                            ">
                                Exit
                            </button>
                            
                            <DeleteDialog 
                                openState={deleteOpen} 
                                handleDeleteDialogState={handleDeleteDialogState} 
                                deleteName={deleteName} 
                                setDeleteName={setDeleteName} 
                                handleDeleteSubmit={handleDeleteSubmit}
                            />
                        </div>
                    </div>
            </div>)}
        </Modal>
    </>
    );
}

export default DeviceInfo;