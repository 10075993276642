import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import api from '../services/api';

export default function BasicMenu({userId, role}) {
    const navigate = useNavigate();
	const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [error, setError] = React.useState('');
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = () => {
        navigate(`/`);
    };

    const handleLogout = async (e) => {
		try {
            console.log("Logging out...");
            dispatch({ type: 'LOGOUT' });
            localStorage.removeItem('token');
            navigate('/login');	            setAnchorEl(null);
        } catch (e) {
            setError('Logout failed');
            console.error('Error:', error);
        }
	};


    const handleDownload = async () => {
        try {
            const url = `/api/research-centers/${userId}/logs`;
            const response = await api.get(url, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'text/csv' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `activity_logs-${new Date().toISOString()}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            setError("Download failed.");
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <Tooltip title="Account settings">
                <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                >
                <Avatar sx={{ width: 40, height: 40 }} />
                </IconButton>
            </Tooltip>
        {role==="user"
        ? (<Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={handleDownload}>Download Logs</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>)
        : (<Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={handleNavigate}>Home</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>)}
        </div>
    );
}