import {Link} from "react-router-dom";
import Button from '@mui/material/Button';

export default function ErrorPage() {
  return(
    <div className='flex flex-col gap-5 w-full h-screen font-base justify-center items-center'>
      <div className=''>
        <h1 className='text-[3rem] text-center text-red-700'>
          Page Error 404
        </h1>
        <h1 className='text-[1.5rem] text-center text-red-700'>
          Professional: Bumalik ka sa inyo
        </h1>
      </div>
      <div>
        {Response.error}
      </div>

      <Link to="/">
        <Button variant="contained" size="large" color='error' className='bg-red-800'>
          Home
        </Button>
      </Link>
    </div>
  )
}