import React, {useState, useEffect} from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import api from '../../services/api';
import SimpleAlert from "../../components/AlertDialog";

const EditRc = ({editOpenState,handleEditOpenState, researchCenterInfo, handleDeleteUpdate}) => {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [reEnterPassword, setPasswordTwo] = useState('');
    const [RCCode, setRCCode] = useState('');
    const [error, setError] = useState('');
    const [description, setDescription] = useState('');

    const [deleteOpen, setDeleteOpen] = useState(false);
    const handleDeleteOpen = () =>{
        setDeleteOpen(!deleteOpen)
    }

    const [showAlert, setShowAlert] = useState(false);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.delete(`/api/research-centers/${researchCenterInfo.id}`);
            handleDeleteOpen()
            handleDeleteUpdate()
        } catch (error) {
            setError("Delete Failed.");
            console.error('Error:', error);
        }
    };

    const RCCDeleteDialog = ({openState, handleDeleteClose}) =>{
        return (
            <Modal
            open={openState}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ bgcolor: 'background.paper', border: '2px solid #000'}}>
                    <div className="flex flex-col items-center p-3">
                        <p id="child-modal-description" className="mt-4 mb-3">
                            Are you sure?
                        </p>
                        <div className="flex flex-row gap-8 mt-5 mb-1">
                            <button onClick={handleDeleteSubmit} className="p-1 bg-red-700 rounded-xl text-white w-20 text-md ">Yes</button>
                            <button onClick={handleDeleteClose} className="p-1 border-2 border-red-700 rounded-xl text-red-700 w-20 text-md">No</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        )
    }

    useEffect(() => {
        setName(researchCenterInfo.name)
        setRCCode(researchCenterInfo.code)
    }, [researchCenterInfo]);

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            if(reEnterPassword!==password){
                setPassword("");
                setPasswordTwo("");
                setError("Update failed. Passwords don't match.");
                setShowAlert(true)
                throw error
            }
            await api.put(`/api/research-centers/${researchCenterInfo.id}`, {
                name: name, 
                code: RCCode, 
                password: password, 
                description: description
            });
            handleEditOpenState()
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
    <>
        <Modal
            open={editOpenState}
            onClose={handleEditOpenState}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            className="font-base">
            <div className="
                flex flex-col border-2 border-black
                h-full w-full bg-white p-6
                sm:w-[65%] sm:h-auto md:w-[55%] lg:w-[45%]
                2xl:w-[20%] w-[85%] bg-[#f2f2f2]
                ">
                <div className="mt-3">
                    <form onSubmit={handleEditSubmit}>
                        <div className= "flex flex-col">
                            <p className="text-red-700 text-lg font-bold mb-4">Edit Research Center</p>
                            <div className="flex gap-3 py-2">
                                <label className="basis-1/4 ml-2">Name</label>
                                <input className="
                                        flex border border-black rounded 
                                        w-[65%] py-2 px-3
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                                        name = "editName"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder= "Material Research Center"
                                    />
                            </div>
                            
                            <div className="flex gap-3 py-1">
                                <label className="basis-1/4 ml-2">Research Center Code</label>
                                <input className="
                                        border border-black rounded 
                                        w-[65%] py-2 px-3
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                                        name = "editRCCode"
                                        value={RCCode}
                                        onChange={(e) => setRCCode(e.target.value)}
                                        placeholder="MRC_00"
                                    />
                            </div>

                            
                            <div className="flex gap-3 py-2">
                                <label htmlFor="description" className="basis-1/4 ml-2">Description</label>
                                <textarea className="
                                        flex border border-black rounded 
                                        w-[65%] py-2 px-3
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                                    id="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    resize="none"
                                    wrap="hard"
                                    placeholder="Add a short description"/>
                            </div>

                            <div className="flex gap-3 py-2"> 
                                <label className="basis-1/4 ml-2">Password</label>
                                <input className="
                                        border border-black rounded 
                                        w-[65%] py-2 px-3
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                        "
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required={true}
                                    placeholder="Password"
                                    />
                            </div>
                            
                            <div className="flex gap-3 py-2">
                                <label className="basis-1/4 ml-2">Re-enter Password</label>
                                <input className="
                                        border border-black rounded 
                                        w-[65%] py-2 px-3
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                                        name="editReEnterPassword"
                                        type="password"
                                        value={reEnterPassword}
                                        onChange={(e) => setPasswordTwo(e.target.value)}
                                        required={true}
                                        placeholder="Re-enter Password"
                                    />
                            </div>
                        </div>
                        <div className="flex flex-row-reverse gap-4 mt-12 mb-4">
                            <button type="button" onClick={handleEditOpenState} className="p-1 border-2 border-red-700 rounded-xl text-red-700 w-1/5 text-md overflow-hidden whitespace-nowrap text-ellipsis">Cancel</button>
                            <button type="button" onClick={handleDeleteOpen} className="p-1 bg-red-700 rounded-xl text-white w-1/4 text-sm overflow-hidden whitespace-nowrap text-ellipsis">Delete</button>
                            { deleteOpen && (
                                    <RCCDeleteDialog openState={deleteOpen} handleDeleteClose={handleDeleteOpen} />
                            )}
                            <button onClick={handleEditSubmit} type="submit" className= "p-1 bg-red-700 rounded-xl text-white w-1/5 text-md overflow-hidden whitespace-nowrap text-ellipsis">Save</button>
                        </div>
                    </form>
                    {showAlert &&
                        <SimpleAlert severity={"error"} message={error} title={"Error"} handleCloseAlert={handleCloseAlert}/>
                    }
                </div>
            </div>
        </Modal>
    </>
    );
}

export default EditRc;