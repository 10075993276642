import React, {useState} from "react";
import Modal from '@mui/material/Modal';
import api from '../../services/api';
import SimpleAlert from "../../components/AlertDialog";

const NewRc = ({newOpenState,handleNewOpenState, handleNewOpenUpdateState}) => {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [reEnterPassword, setPasswordTwo] = useState('');
    const [RCCode, setRCCode] = useState('');
    const [error, setError] = useState('');
    const [description, setDescription] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if(reEnterPassword!==password){
                setPassword("");
                setPasswordTwo("");
                setError('Creation failed. Please check your credentials and try again.');
                setShowAlert(true);
                throw error
            };
            await api.post('/api/research-centers', {
                code: RCCode,
                name: name,
                password: password,
                description: description
            });
            handleNewOpenUpdateState();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
    <React.Fragment>
        {showAlert &&
            <SimpleAlert severity={"error"} message={error} title={"Error"} handleCloseAlert={handleCloseAlert}/>
        }
        <Modal
            open={newOpenState}
            onClose={handleNewOpenState}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="
                flex flex-col border-2 border-black
                h-full w-full bg-white p-6
                sm:w-[65%] sm:h-auto md:w-[55%] lg:w-[45%]
                2xl:w-[20%] w-[85%] bg-[#f2f2f2]
                ">
                <div className="mt-3">
                    <form onSubmit={handleSubmit}>
                        <div className= "my-5 flex flex-col">
                            <p className="text-red-700 text-lg text font-bold mb-2">Add New Research Center</p>
                            <div className="flex gap-3 py-2">
                                <label htmlFor="name" className="basis-1/4">Name</label>
                                <input className="
                                        flex border border-black rounded 
                                        w-[65%] py-2 px-3
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required={true}
                                    placeholder="Name"
                                />
                            </div>

                            <div className="flex gap-3 py-2">
                                <label htmlFor="RCCode" className="basis-1/4">Research Center Code</label>
                                <input className="
                                        flex border border-black rounded 
                                        w-[65%] py-2 px-3
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                                    id="RCCode"
                                    value={RCCode}
                                    onChange={(e) => setRCCode(e.target.value)}
                                    required={true}
                                    placeholder="Research Center Code"
                                />
                            </div>

                            <div className="flex gap-3 py-2">
                                <label htmlFor="description" className="basis-1/4">Description</label>
                                <textarea className="
                                        flex border border-black rounded 
                                        w-[65%] py-2 px-3
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                                    id="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required={true}
                                    resize="none"
                                    wrap="hard"
                                    placeholder="Add a short description"/>
                            </div>

                            <div className="flex gap-3 py-2">
                                <label htmlFor="password" className="basis-1/4">Password</label>
                                <input className="
                                        flex border border-black rounded 
                                        w-[65%] py-2 px-3
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required={true}
                                    placeholder="Enter Password"
                                />
                            </div>

                            <div className="flex gap-3 py-2">
                                <label htmlFor="reEnterPassword" className="basis-1/4">Re-enter Password</label>
                                <input className="
                                        flex border border-black rounded 
                                        w-[65%] py-2 px-3
                                        text-gray-700 leading-tight
                                        focus:outline-none focus:shadow-outline focus:border-red-600 focus:shadow-red-800 focus:shadow-sm
                                    "
                                    type="password"
                                    id="reEnterPassword"
                                    value={reEnterPassword}
                                    onChange={(e) => setPasswordTwo(e.target.value)}
                                    required={true}
                                    placeholder="Re-enter Password"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row-reverse gap-4 mb-4">
                            <button type="button" onClick={handleNewOpenState} className="p-1 border-2 border-red-700 rounded-xl text-red-700 w-1/5 text-md overflow-hidden whitespace-nowrap text-ellipsis">Cancel</button>
                            <button type="submit" className= "p-1 bg-red-700 rounded-xl text-white w-1/5 text-md overflow-hidden whitespace-nowrap text-ellipsis">Add</button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    </React.Fragment>
    );
}

export default NewRc;