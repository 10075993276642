import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import DeviceInfo from "./DeviceInfo";
import AddDevice from "./AddDevice";
import api from '../../services/api';
import { useParams } from "react-router-dom";
import Widget from '../../components/Widget';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {Box, Card, CardActionArea, CardContent, Pagination, Typography,IconButton, Skeleton} from '@mui/material';
import { keyframes } from '@emotion/react';
import BasicMenu from '../../components/AccountMenu';
import SimpleAlert from '../../components/AlertDialog';

const floatAnimation = keyframes`
	0% {
    	transform: translateY(0);
	}
	25% {
    	transform: translateY(-4px);
	}
	50% {
    	transform: translateY(0);
	}
	75% {
    	transform: translateY(4px);
	}
	100% {
    	transform: translateY(0);
	}
`;

const StaffHome = () => {
	const user = useSelector(state => state.auth.user);
	const { researchcenterid } = useParams()

	const [open, setOpen] = useState(false);
	const openState = open
	const [currentId, setCurrentId] = useState("");

	const [error, setError] = useState('');
	const [loadingEquipmentList, setLoadingEquipmentList] = useState(true);
	const [reading, setReading] = useState({});
	const [equipmentList, setEquipmentList] = useState([]);
	const [refreshState, setRefreshState] = useState(false);
	const [newDeviceOpenState, setNewDeviceOpenState] = useState({});
	const [alertObject, setAlertObject] = useState({severity:"success",message:"", title:"Success",showAlert:false});

	const handleCloseAlert = () => {
        setAlertObject((prevState) => ({
			...prevState,
			showAlert: false,
		}))
    };

	const openCreationModal = (item) => {
		setNewDeviceOpenState(prevState => ({
			...prevState,
			[item]: !prevState[item]
		}));
	};

	const closeCreationModal = (item) => {
		setNewDeviceOpenState(prevState => ({
			...prevState,
			[item]: !prevState[item]
		}));
		setRefreshState(true)
	};

	const handleOpen = (equipmentId) => {
		setCurrentId(equipmentId);
		setOpen(true);
	};

	const handleClose = () => {
		setCurrentId("");
		setOpen(false);
	};

	const handleChangesAfterClose = () => {
		setRefreshState(true)
		setCurrentId("");
		setOpen(false);
	};

	useEffect(() => {
		const fetchEquipmentList = async (id) => {
			try {
				const response = await api.get(`api/research-centers/${id}/equipments`);
				if(response.status===404){
					setAlertObject({
						severity:"info",message:"This center has no equipment", title:"Information",showAlert:true
					})
				}
				setEquipmentList(response.data);
				setLoadingEquipmentList(false);
			} catch (error) {
				setError('Fetching failed');
				console.error('Error:', error);
			}
		}

		fetchEquipmentList(researchcenterid);

		if(refreshState){
			fetchEquipmentList(researchcenterid)
			setRefreshState(false)
		}
	}, [researchcenterid,refreshState]);

	// Fetch the latest environement readings of research center [:id]
	useEffect(() => {
		const fetchLatestReading = async () => {
			try {
				const response = await api.get(`/api/research-centers/${researchcenterid}/environment-reading`);
				setReading(response.data);
			} catch (error) {
				console.error('Error fetching latest reading:', error);
			}
		};
		fetchLatestReading();
	}, [researchcenterid]);

	const [pagination, setPagination] = useState({});
	const itemsPerPage = 5;  
    const handlePageChange = (event, value, type) => {
        setPagination(prevState => ({
            ...prevState,
            [type]: value
        }));
    };
    
	const getStatusColor = (status) => {
		switch (status) {
		case 'available':
			return 'rgb(22 101 52)'; // green
		case 'work-in-progress':
			return 'rgb(234 88 12)'; // yellow
		case 'for repair':
			return 'rgb(153 27 27)'; // red
		default:
			return 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'; // default color
		}
	};

	return(
		<div className="flex flex-col min-w-screen min-h-screen font-base bg-[#f2f2f2]">
			<div className="flex flex-col bg-red-800 p-10">
				<div className="flex justify-between items-center">
					<p className="text-white md:text-3xl text-lg">
						<span className='block md:inline md:text-3xl text-xl'>
							{"STEERHub REM:  "}
						</span>
						<span className='block md:inline'>{ user.name }</span>
					</p>
					<BasicMenu userId={researchcenterid} role={"user"}/>
				</div>
				<div className="text-white flex flex-col">
					<AddDevice
						handleOpenState = {() => openCreationModal("New")}
						handleClose={() =>closeCreationModal("New")}
						openState ={newDeviceOpenState["New"] || false}
						equipmentType = ""
						rcId = {researchcenterid}
						setAlertObject={setAlertObject}
					/>
				</div>
			</div>
			
			<div className="flex flex-col p-6">
				<div className='flex md:flex-row md:gap-x-5 flex-col gap-y-2 justify-center mb-7'>
					<Widget type={'Humidity'} reading={reading.humidity}  />
					<Widget type={'Temperature'} reading={reading.temperature} />
				</div>
				<Box sx={{width: 1, margin: '0 auto', padding: 2 }}>
					{equipmentList.map((equipment, equipmentIndex) => {
						const { type, models } = equipment;
						const pageCount = Math.ceil(models.length / itemsPerPage);
						const currentPage = pagination[type] || 1;
						const displayedModels = models.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

						return (
						<Box key={equipmentIndex} sx={{ marginBottom: 4 }}>
							<Typography variant='h4' sx={{textAlign: 'center', mb: 5 }}>
								{loadingEquipmentList ? <Skeleton height="100px"/> : type}
								{loadingEquipmentList 
									?<></> 
									:<IconButton onClick={() => openCreationModal(type)} sx={{color:"black"}}>
										<AddCircleOutlineOutlinedIcon sx={{fontSize:"1.25rem"}}/>
									</IconButton>
								}
							</Typography>
							{loadingEquipmentList
								? <div className="flex flex-col flex-wrap gap-4 lg:flex-row">
									<Skeleton variant="rounded" height="150px" className="w-full lg:w-[19%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
									<Skeleton variant="rounded" height="150px" className="w-full lg:w-[18%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
									<Skeleton variant="rounded" height="150px" className="w-full lg:w-[19%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
									<Skeleton variant="rounded" height="150px" className="w-full lg:w-[18%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
									<Skeleton variant="rounded" height="150px" className="w-full lg:w-[19%] 2xl:min-h-[225px] 3xl:min-h-[300px]"/>
                				</div>
								:<></>
							}
							<AddDevice
								handleOpenState = {() => openCreationModal(type)}
								handleClose={() => closeCreationModal(type)}
								openState ={newDeviceOpenState[type] || false}
								equipmentType = {type}
								rcId = {researchcenterid}
								setAlertObject={setAlertObject}
							/>
							<Box
							sx={{
								display: 'flex',
								flexDirection: { xs: 'column', md: 'row' },
								alignItems: 'center',
								gap: 2,
								flexWrap: 'wrap',
							}}
							>
							{loadingEquipmentList 
							? <></>
							: (
								displayedModels.map((model, modelIndex) => (
									<React.Fragment>
										<Card
										key={modelIndex}
										sx={{
											width: { xs: '100%', md: 'calc(20% - 16px)' },
											height: 150,
											boxShadow: 3,
											background: getStatusColor(model.status),
										}}
										>
											<CardActionArea sx={{ height: '100%' }} onClick={() => handleOpen(model.id)}>
												<CardContent
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													height: '100%',
													color: 'white',
												}}
												>
												<Typography variant='h5' component='div' className='text-center'>
													{model.name}
												</Typography>
												</CardContent>
											</CardActionArea>
										</Card>
									</React.Fragment>
								))
							)}
							</Box>
							{models.length > itemsPerPage && (
							<Pagination
								count={pageCount}
								page={currentPage}
								onChange={(event, value) => handlePageChange(event, value, type)}
								sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
							/>
							)}
						</Box>
						);
					})}
					<Box sx={{ 
						backgroundColor: 'rgba(175, 175, 175, 0.35)',
						mb: 4, borderRadius: '10px', 
						height:"120px",
						display: 'flex', 
						justifyContent: 'center', 
						alignItems: 'center'}}>
						<Typography sx={{textAlign: 'center'}}>
							<IconButton onClick={() => openCreationModal("New")} sx={{color:"rgba(89, 89, 89)",animation: `${floatAnimation} 3s linear infinite`,}}>
								<AddCircleOutlineOutlinedIcon sx={{ fontSize: "2.75rem" }}/>
							</IconButton>
						</Typography>
					</Box>
				</Box>
			</div>
			{openState &&
				<DeviceInfo
					open={openState}
					handleClose={handleClose}
					equipmentId={currentId}
					handleChangesAfterClose={handleChangesAfterClose}
					researchCenterId={researchcenterid}
					setAlertObject={setAlertObject}
				/>
			}
			{alertObject.showAlert &&
                <SimpleAlert severity={alertObject.severity} message={alertObject.message} title={alertObject.title} handleCloseAlert={handleCloseAlert}/>
            }
		</div>
	)
};

export default StaffHome;