import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import HumidityImg from '../assets/img/2.svg';
import TemperatureImg from '../assets/img/1.svg';

const imageMap = {
    Humidity: HumidityImg,
    Temperature: TemperatureImg,
};

export default function Widget({type, reading}) {
    return (
        <Card sx={{
            display: 'flex',
            width: '100%',
            maxWidth: '600px',
            '@media (max-width: 768px)': {
                maxWidth: '100%', 
            },
        }} elevation={24}>
            <CardMedia
                component="img"
                sx={{ width: 155 }}
                image={imageMap[type]}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                        {type}
                    </Typography>
                    <Typography variant="h2" color="text.secondary" component="div">
                    {reading ? (type === 'Humidity' ? `${reading}%` : `${reading}°C`) : 'Loading...'}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    );
}