import React, { Suspense } from 'react';
import { Modal, Paper, CardContent, CardMedia, Typography, Skeleton } from '@mui/material';
import Model from './Model';
import Default from '../assets/img/default.svg';

export default function PublicCard({ open, handleClose, equipment }) {    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            className='flex align-middle '
        >
            <Paper 
                elevation={24}
                sx={{
                    width: 'fit-content',
                    maxWidth: 332,
                    padding: 2,
                }}
            >
                {equipment.model3D ?
                    (
                        <Model url={`https://api-projecty.parallaxed.ph/api/uploads/${equipment.model3D}`} />
                    ) :
                    (
                        <CardMedia 
                            component="img" 
                            sx={{ width: 300 }}
                            image={Default}
                        />
                    )
                }
                <CardContent>
                    <Typography id="modal-modal-title" variant="h5" component="div">
                        {equipment ? equipment.model : <Skeleton/>}
                    </Typography>
                    <Typography gutterBottom id='modal-modal-subtitle' variant='h7' component='div'>
                        {equipment ? equipment.type : <Skeleton />}
                    </Typography>
                    <Typography id="modal-modal-description" variant="body2" color="text.secondary">
                        {equipment ? equipment.description : <Skeleton />}
                    </Typography>
                </CardContent>
            </Paper>
        </Modal>
    );
}
