import moment from 'moment-timezone';
import React from "react";

const DateDisplay = ({equipmentLog, equipmentStatus}) => {
    const units = [
        { name: 'week', limit: 7 * 24 * 60 * 60 * 1000, inMilliseconds: 7 * 24 * 60 * 60 * 1000 },
        { name: 'day', limit: 24 * 60 * 60 * 1000, inMilliseconds: 24 * 60 * 60 * 1000 },
        { name: 'hour', limit: 60 * 60 * 1000, inMilliseconds: 60 * 60 * 1000 },
        { name: 'minute', limit: 60 * 1000, inMilliseconds: 60 * 1000 },
        { name: 'second', limit: 1000, inMilliseconds: 1000 }
    ];

    const getDifference = (differenceInMillis) => {
        for (let i = 0; i < units.length; i++) {
            if (differenceInMillis >= units[i].limit) {
                const value = Math.floor(differenceInMillis / units[i].inMilliseconds);
                return `${value} ${units[i].name}${value !== 1 ? 's' : ''} ago`;
            }
        }
        return 'just now';
    };

    const convertDate = (dateInIsoFormat,equipmentStatus) => {
        const dateInProperFormat = moment(dateInIsoFormat).tz('Asia/Singapore');
        if(equipmentStatus==='available'){
            const formattedDate = dateInProperFormat.format('MM/DD/YYYY HH:mm')
            return formattedDate
        }
        const currentTime = moment()
        const differenceInMS = currentTime.diff(dateInProperFormat)
        return getDifference(differenceInMS)
    }

    const displayTimestamps = () => {
        switch(equipmentStatus){
            case "available":
                if(equipmentLog===undefined){
                    return <></>
                }
                else if(equipmentLog.lastUsed === null){
                    return <></>
                }else{
                    const displayDate = convertDate(equipmentLog.lastUsed, equipmentStatus)
                    return (
                        <div className='flex'>
                            <p className='w-2/5'>Last Used:</p> 
                            <p className='w-3/5'>{displayDate}</p>
                        </div>
                    )
                }
            case "for repair":
                const downtimeDisplay = convertDate(equipmentLog.downtime, equipmentStatus)
                return (
                    <div className='flex'> 
                        <p className='w-2/5'>Downtime:</p>
                        <p className='w-3/5'>{downtimeDisplay}</p>
                    </div>
                )
            case "work-in-progress":
                const uptimeDisplay = convertDate(equipmentLog.uptime, equipmentStatus)
                return (
                    <div className='flex'>
                        <p className='w-2/5'>Uptime:</p>
                        <p className='w-3/5'>{uptimeDisplay}</p>
                    </div>
                )
            default:
                return <></>
        }
    }

    return (
        <>
            {displayTimestamps()}
        </>
    )
};

export default DateDisplay;