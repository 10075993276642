import React, { Suspense } from 'react';
import { Canvas, useLoader, extend } from '@react-three/fiber';
import { OrbitControls, Html } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Card, Skeleton } from '@mui/material';

const Object = ({ urlPath }) => {
    const gltf = useLoader(GLTFLoader, urlPath);
    return <primitive object={gltf.scene} position={[0, -1, 0]} />;
};

const Model = ({ url }) => {
    return (
        <Card sx={{ width: 300, height: 300 }} intensity={24} variant='outlined'>
            <Canvas camera={{ position: [-15, 5, 15], fov: 20 }}>
                <directionalLight 
                    position={[3.3, 1.0, 4.4]}
                    castShadow
                    intensity={Math.PI}
                />
                <ambientLight />
                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                <pointLight position={[10, 10, 10]} />
                <Suspense fallback={<Html><Skeleton /></Html>}>
                    <Object urlPath={url} />
                </Suspense>
                <OrbitControls
                    enableZoom={false}
                    enableDamping={true}
                    dampingFactor={0.1}
                    rotateSpeed={0.5}
                />
            </Canvas>
        </Card>
    );
};

export default Model;
