import React, { useState, useEffect  } from 'react';
import {Skeleton, IconButton} from '@mui/material';
import { keyframes } from '@emotion/react';
import NewRc from './NewRC';
import EditRc from './EditRC';
import api from '../../services/api';
import BasicMenu from '../../components/AccountMenu';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SimpleAlert from '../../components/AlertDialog';

const floatAnimation = keyframes`
	0% {
    	transform: translateY(0);
	}
	25% {
    	transform: translateY(-4px);
	}
	50% {
    	transform: translateY(0);
	}
	75% {
    	transform: translateY(4px);
	}
	100% {
    	transform: translateY(0);
	}
`;

const AdminHome = () => {
    const [newOpenState, setNewOpenState] = useState(false);
    const [showAlert,setShowAlert] = useState(false);
    const handleNewOpenState = () =>{
        setNewOpenState(!newOpenState)
    }

    const handleNewOpenUpdateState = () =>{
        fetchData();
        setNewOpenState(false);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const [editOpenState, setEditOpenState] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [error, setError] = useState('');

    const [userList, setUserList] = useState([]);
    const handleEditOpen = (user) => {
        setCurrentUser(user);
        setEditOpenState(true);
    };
    
    const handleEditClose = () => {
        fetchData()
        setCurrentUser(null);
        setEditOpenState(false);
    };
    
    const handleDeleteUpdate = () =>{
        fetchData()
        setEditOpenState(false)
    }

    const fetchData = async (e) =>{
        try {
            const response = await api.get('api/research-centers');
            if(response.status===404){
                setShowAlert(true);
            }
            setUserList(response.data)
        } catch (error) {
            setError('Cannot get data.');
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className = "min-w-full bg-[#f2f2f2]">
            <div className="flex flex-col bg-red-800 p-8">
				<div className="flex justify-between items-center">
					<p className="text-white md:text-3xl text-lg">
						<span className='block md:inline md:text-3xl text-xl'>
							{"STEERHub REM: "}
						</span>
						<span className='block md:inline'>Admin</span>
					</p>
					<BasicMenu userId={null} role={"admin"}/>
				</div>
				{newOpenState && (
                    <NewRc newOpenState={newOpenState} handleNewOpenState={handleNewOpenState} handleNewOpenUpdateState={handleNewOpenUpdateState}/>
                )}
			</div>
            {/* <div className="flex border-b-2 border-red-700 px-8 py-6 justify-between">
                <p className="text-red-700 text-xl text font-bold">Users List</p>
                <div>
                    <button className="p-2 bg-red-700 rounded-md text-white w-fit text-xs" onClick={handleNewOpenState}>Add Research Center</button>
                    {newOpenState && (
                        <NewRc newOpenState={newOpenState} handleNewOpenState={handleNewOpenState} handleNewOpenUpdateState={handleNewOpenUpdateState}/>
                    )}
                    <button className="p-2 ml-4 bg-red-700 rounded-md text-white text-xs" onClick={logout}>Logout</button>
                </div>
            </div> */}
            <div className="col-span-3 w-full">
                {userList ? 
                    (<React.Fragment>
                        <ul>
                            {userList.map((user) => (
                            <li key={user.id}>
                                <div className="h-auto border-b-2 border-red-700 text-md my-8 pb-6 flex justify-between px-8 py-1">
                                    <p className="w-[50%] lg:w-[30%] text-wrap break-words">
                                        {user.name}
                                    </p>
                                    <p className="w-1/5 text-xs font-thin text-neutral-700 break-words">
                                        Code: {user.code} <br></br>
                                        Password: *********
                                    </p>
                                    <button className="p-1 bg-red-700 rounded-md text-white w-[10%] md:w-[8%] h-full text-sm md:mr-8 overflow-hidden whitespace-nowrap text-ellipsis" onClick={() => handleEditOpen(user)}>Edit</button>
                                    {editOpenState && (
                                        <EditRc editOpenState={editOpenState} handleEditOpenState={handleEditClose} researchCenterInfo={currentUser} handleDeleteUpdate={handleDeleteUpdate}/>
                                    )}
                                </div>
                            </li>
                            ))}
                        </ul>
                        <div className="h-auto border-b-2 border-red-700 text-md my-8 pb-6 flex px-8 py-1 justify-center">
                            <IconButton onClick={handleNewOpenState} sx={{color:"rgba(89, 89, 89)",animation: `${floatAnimation} 3s linear infinite`,}}>
                                    <AddCircleOutlineOutlinedIcon sx={{ fontSize: "1.7rem" }}/>
                            </IconButton>
                        </div>
                    </React.Fragment>
                    ) : (<ul> 
                            <li>
                                <div className="border-b-2 border-red-700 text-md flex justify-between px-8 py-5">
                                    <Skeleton variant="rounded" width="100%" height={100}/>
                                </div>
                            </li>
                            <li>
                                <div className="border-b-2 border-red-700 text-md flex justify-between px-8 py-5">
                                    <Skeleton variant="rounded" width="100%" height={100}/>
                                </div>
                            </li>
                            <li>
                                <div className="border-b-2 border-red-700 text-md flex justify-between px-8 py-5">
                                    <Skeleton variant="rounded" width="100%" height={100}/>
                                </div>
                            </li>
                        </ul>
                    )
                }
            </div>
            {showAlert &&
                <SimpleAlert severity={"info"} message={"No research centers found"} title={"Information"} handleCloseAlert={handleCloseAlert}/>}
        </div>
    );
};

export default AdminHome;